<template>
    <div class="page-not-found-wrap">
        <h2>Page not found.</h2>
        <p>The page you are trying to access does not exist on our application.</p>
        <p>Please use the menu to navigate to the desired page.</p>
    </div>
</template>



<script>
export default {
    
}
</script>



<style>

.page-not-found-wrap {
    margin-top: 120px;
    text-align: center;
}
    
</style>