<template>
    <div class="nav-wrap">
        <img class="menu-logo" @click="scrollTo('intro')" src="@/assets/multi-tag-logo-only.png" alt="multi-tag-menu-logo">
        <p @click="scrollTo('solutions')">Solutions</p>
        <p @click="scrollTo('team')">Our Team</p>
        <p @click="scrollTo('contact')">Contact Us</p>
    </div>
</template>




<script>
export default {
    data() {
        return {

        }
    },


    methods: {

        scrollTo: function(id) {
            if(document.getElementById(id))
                window.scrollTo(0, document.getElementById(id).offsetTop + 300);
            else
                this.$router.push('/');
        }
        
    }
}
</script>




<style>

.nav-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    display: flex;
    align-items: center;
    background: rgb(50,50,50);
    color: white;
    height: 40px;
    width: 100%;
    padding: 0 20px;
}


.nav-wrap p {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 25px;
    cursor: pointer;
}


.nav-wrap p:last-child {
    margin-right: 0;
}



.menu-logo {
    max-height: 30px;
    margin-right: 15px;
}



@media only screen and (max-width: 600px) {

    /* .nav-wrap {
        justify-content: space-evenly;
    } */


    .nav-wrap p {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin-right: 25px;
        cursor: pointer;
    }
}
    
</style>