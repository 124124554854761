import axios from 'axios'

// console.log('Location hostname: ', window.location.hostname);


if(process.env.NODE_ENV == 'development')
{
    axios.defaults.baseURL = 'http://localhost/OtherApis/multitag';
}
else
{
    axios.defaults.baseURL = 'https://multitag.co.za/api';
    // axios.defaults.baseURL = 'https://projects.bravado.co.za/api';
}


export {
    axios
}