<template>
    <div class="landing-wrap">
        <div class="landing-wrap-cover"></div>

        <div class="logo-hero-img-wrap">
            <img class="logo-hero-img" src="@/assets/multi-tag-logo.png" alt="multi-tag-logo">
        </div>


        


        <div class="sections-wrap">

            <div id="intro" class="intro-wrap">
                <h2>The Smart, Affordable RFID Solution You Can Rely On</h2>

                <p>Finding the right RFID solution, sometimes require a choice between effectiveness and affordability. With <span class="bold">Multi Tag</span>, you don’t have to compromise. Our RFID system is <span class="bold">simple, affordable, and effective</span> designed to make monitoring effortless—without the need for complex setups or specialized expertise.</p>
                <p>Born from real-world challenges, Multi Tag was initially developed for <span class="bold">livestock management</span> in rugged, mountainous terrain, where tracking cattle and preventing theft was critical. What started as a <span class="bold">practical solution for farmers</span> quickly evolved into a <span class="bold">versatile RFID system</span> with applications across multiple industries.</p>
                <p>Whether you need to monitor assets, inventory or livestock, Multi Tag provides a <span class="bold">reliable, easy-to-use RFID solution</span> that works for you.</p>
                <p><span class="bold">Let’s talk!</span> <span @click="scrollTo('contact')">Contact us</span> today to learn how Multi Tag can simplify your management and monitoring needs.</p>

                <video src="@/assets/STU Video.mp4" width="360" height="640" controls></video>
            </div>

            <div id="solutions" class="solutions-wrap">
                <h2>Solutions</h2>

                <img class="heading-img" src="@/assets/asset_monitoring_1.svg" alt="asset-monitoring">
                <h3>Asset monitoring</h3>
                <p>Our asset monitoring and management system is ideal for any size enterprise.</p>
                <p>The tailor-to-fit solution operates via our unique customised dashboard and all information on your assets is safely stored in our cloud facility. The solution can meet the demands of all business sectors.</p>

                <img class="heading-img" src="@/assets/anti-theft.svg" alt="theft-prevention">
                <h3>Theft prevention</h3>
                <p>Notifications and alarms are activated as soon as the asset is removed from its stationed area to prompt a timeous response to possible theft.</p>
                <p>Complete information is stored within the cloud facility, enabling the user to access all information, at any given time with any laptop pc, desktop pc or mobile device.</p>

                <img class="heading-img" src="@/assets/inventory_management.svg" alt="inventory-management">
                <h3>Inventory management</h3>
                <p>The system is designed to enable real-time inventory counts, therefor constantly synchronizing physical inventory with theoretical inventory. This is done without any human intervention and reduces inventory discrepancies, resulting in accurate valuation of inventory on a daily basis.</p>

                <img class="heading-img" src="@/assets/drone-capable.svg" alt="drone-capable">
                <h3>Drone capabilities</h3>
                <p>Our system is designed with the future in mind. RFID readers are drone capable, enabling the user to perform physical inventory counts in the open air or large storage facilities. Once the count and survey of the inventory area is completed, the system uploads all counts and activities when the drone returns for docking.</p>
                <p>The drone application is compatible with all tags available in our system setup.</p>

                <img class="heading-img large" src="@/assets/multiple-applications.svg" alt="multiple-applications">
                <h3>Fields of Application</h3>
                <p>The system can be applied to the following areas of business, and many more:</p>
                <div class="ul-wrap">
                    <ul>
                        <li>Agriculture</li>
                        <li>Transport and Logistics</li>
                        <li>Schools and other learning campuses</li>
                        <li>Warehousing</li>
                        <li>Mining and Engineering</li>
                    </ul>
                </div>

            </div>
            <div id="team" class="team-wrap">
                <h2>Our Team</h2>
                <div class="team-cards-wrap">
                    <div class="team-card">
                        <div class="card-face-wrap"></div>
                        <div class="card-name-wrap">
                            <h4>The Main Brain</h4>
                            <h5>Hubert Adendorff</h5>
                        </div> 
                    </div>
                    <div class="team-card">
                        <div class="card-face-wrap"></div>
                        <div class="card-name-wrap">
                            <h4>The Tech Genius</h4>
                            <h5>Christo "watse van?"</h5>
                        </div>
                    </div>
                    <div class="team-card">
                        <div class="card-face-wrap"></div>
                        <div class="card-name-wrap">
                            <h4>The Web Wizard</h4>
                            <h5>Chris van Lille</h5>    
                        </div>            
                    </div>
                </div>
            </div>
            <div id="contact" class="contact-wrap">
                <h2>Contact</h2>
                <p>Hubert Adendorff</p>
                <p>+27 (0)82 825 7577</p>
                <p>hubert@multitag.co.za</p>

                <div class="message-wrap">
                    <h3>Send us a message</h3>
                    <input type="text" v-model="contact.name" placeholder="Name" :class="{ error: error.name }" @focus="error.name ? contact.name = '' : null, error.name = false">
                    <input type="text" v-model="contact.surname" placeholder="Surname" :class="{ error: error.surname }" @focus="error.surname ? contact.surname = '' : null, error.surname = false">
                    <input type="text" v-model="contact.phoneNumber" placeholder="Contact Number" :class="{ error: error.phoneNumber }" @focus="error.phoneNumber ? contact.phoneNumber = '' : null, error.phoneNumber = false">
                    <input type="text" v-model="contact.email" placeholder="Email" :class="{ error: error.email }" @focus="error.email ? contact.email = '' : null, error.email = false">
                    <textarea v-model="contact.message" placeholder="Your message"></textarea>
                    
                </div>

                <h4 style="color: red; " v-if="sendingError === true">There was an error sending your message, please try again later or use the contact details above to get in touch with us.</h4>
                <h4 style="color: green; " v-if="messageSent === true">Thank you for your message, we will get in touch with you soon.</h4>
                
                <button class="btn" @click="submitMessage()">Submit</button>
                
                
            </div>
        </div>
        
        <div class="copyright-wrap">
            <p>copyright &copy; <span>multitag.co.za</span> {{ year }}</p>
        </div>
    </div>
</template>




<script>
import { axios } from '../axios/axios'

export default {



    data() {
        return {
            year: new Date().getFullYear(),

            contact: {
                name: '',
                surname: '',
                phoneNumber: '',
                email: '',
                message:''
            },


            error: {
                name: false,
                surname: false,
                phoneNumber: false,
                email: false,
            },

            sendingError: false,
            messageSent: false,

            regexEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },




    watch: {
        contact: {
            handler: function() {
                this.sendingError = false;
                this.messageSent = false;
            },
            deep: true
        }
    },




    methods: {
        scrollTo: function(id) {
            if(document.getElementById(id))
                window.scrollTo(0, document.getElementById(id).offsetTop + 300);
            else
                this.$router.push('/');
        },




        

        submitMessage: async function() {


            if(!this.validateInput())
                return


            this.sendingError = false;
            this.messageSent = false;


            this.$recaptcha("login").then(async (token) => {
                console.log('Recaptcha OK!');
                console.log('Sending message...');

                

                var body = `<h3>You have a new website request from: ${ this.contact.name } ${ this.contact.surname ? this.contact.surname : '' }:</h3><br>`;
                body += `<p>${ this.contact.message }</p><br><br>`;
                body += `<h4>Here are their contact details:</h4>`;
                body += `<p>Name: ${ this.contact.name }</p>`;
                body += `<p>Surname: ${ this.contact.surname }</p>`;
                body += `<p>Email: ${ this.contact.email }</p>`;
                body += `<p>Phone: ${ this.contact.phoneNumber }</p>`;



                axios.post('/sendmail.php', { fromName: this.contact.name, fromSurname: this.contact.surname, body })
                .then(resp => {
                    console.log(resp)
                    if(resp.data.status == 'success')
                    {
                        this.messageSent = true;
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.sendingError = true;
                })  
            })
        },






        validateInput: function() {

            console.log(JSON.parse(JSON.stringify(this.contact)))

            var flag = true;

            if(this.contact.email === '' || !this.regexEmail.test(this.contact.email)) {
                this.error.email = true;
                this.contact.email = 'Please enter a valid email address';
                flag = false
            }


            if(!this.contact.name)
            {
                this.error.name = true;
                this.contact.name = 'Please enter your name';
                flag = false;
            }


            if(!this.contact.phoneNumber || this.contact.phoneNumber && this.contact.phoneNumber.toString().length < 10)
            {
                this.error.phoneNumber = true;
                this.contact.phoneNumber = 'Please enter a valid contact number';
                flag = false;
            }


            return flag;
        }


    }
}
</script>




<style>

.grecaptcha-badge {
  z-index: 1000;
}



.landing-wrap {
    margin-top: 40px;
    position: relative;
    background-image: url('@/assets/parallax2.jpeg');
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 100%; */
}

.landing-wrap-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(220,220,220, 0.5);
    z-index: 1;
}






.logo-hero-img-wrap {
    padding: 40px 0;
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.logo-hero-img {
    position: relative;
    z-index: 2;
}










.sections-wrap {
    position: relative;
    z-index: 100;
}

.solutions-wrap,
.team-wrap,
.contact-wrap,
.intro-wrap {
    margin: 40px 20px;
    background: rgba(250,250,250, 0.8);
    border-radius: 10px;
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20%;
    padding-bottom: 100px;
}



.solutions-wrap {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20%;
    padding-bottom: 100px;
}


.solutions-wrap h2,
.team-wrap h2,
.contact-wrap h2,
.intro-wrap h2 {
    margin-top: 60px;
    font-size: 50px;
}




.solutions-wrap h3,
.team-wrap h3,
.contact-wrap h3,
.intro-wrap h3 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 26px;
}





.intro-wrap h2 {
    text-wrap: balance;
    margin-bottom: 60px;
}


.intro-wrap p {
    margin-bottom: 30px;
}

.intro-wrap p span.bold {
    font-weight: 700;
    color: #F68519;
}


.intro-wrap video {
    margin-top: 20px;
}



.heading-img {
    margin-top: 120px;
    width: 80px;
    text-align: center;
}

.heading-img.large {
    width: 180px;
}


.solutions-wrap p,
.intro-wrap p {
    font-size: 20px;
    text-wrap: balance;
}



.ul-wrap {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 20px;
}


.solutions-wrap ul {
    text-align: left;
}

.solutions-wrap ul li {

}






.team-wrap {
    padding: 0;
    padding-bottom: 100px;
}

.team-cards-wrap {
    margin-top: 60px;
    display: flex;
    
    justify-content: space-evenly;
    width: 100%;
}



.team-card {
    /* border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 3px solid #F68519;
    border-bottom: 3px solid #F68519; */
    margin-right: 50px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    flex-direction: row;
}

.team-card:last-child {
    margin-right: 0;
}



.card-face-wrap {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 1px solid black;
    margin: 10px;
    margin-right: 20px;

}


.card-name-wrap {
    text-align: left;
}


.card-name-wrap h4 {
    font-size: 24px;
    
}

.card-name-wrap h5 {
    font-size: 18px;
    color: #F68519;
}











.contact-wrap {
    padding-bottom: 70px;
}

.contact-wrap p {
    font-size: 20px;
    margin-top: 20px;
}


.contact-wrap h3 {
    margin-top: 60px;
}




.message-wrap {
    display: flex;
    flex-direction: column;
}


.message-wrap input.error {
    color: red;
}

.message-wrap input, textarea {
    background: transparent;
    border: none;
    border-bottom: 2px solid #F68519;
    margin: 20px 0;
    min-width: 200px;
    width: 300px;
    resize: none;
}


.message-wrap textarea {
    height: 100px;
    border: 2px solid #F68519;
    border-radius: 5px;
    padding: 3px 5px;
}


.contact-wrap button {
    padding: 8px 12px;
    background: rgba(0,0,0,0.75);
    border: none;
    color: #F68519;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 30px;
    border-radius: 5px;
}





.copyright-wrap {
    position: relative;
    width: 100%;
    background: rgba(0,0,0, 0.75);
    color: white;
    padding: 10px 0;
    text-align: center;
    z-index: 2;
}

.copyright-wrap span {
    color: #F68519;
}



@media only screen and (max-width: 600px) {
    .solutions-wrap,
    .intro-wrap {
        padding: 0 5%;
        padding-bottom: 100px;
    }

    .logo-hero-img {
        width: 80%;
    }


    .team-cards-wrap {
        flex-direction: column;
    }

    .team-card {
        margin: 0;
        margin-bottom: 40px;
        padding-left: 20px;
    }


    .intro-wrap video {
        max-width: 80%;
    }
}
    
</style>