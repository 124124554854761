import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import P404 from '../views/P404.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Landing
  },
  // Page does not exist - Catch All Navigation for links that don't exist
    // keep at the bottom, otherwise it will trigger before any routes listed below it.
    {
      path: '/:pathMatch(.*)',
      component: P404,
      meta: {
          title: 'Page Not Found...'
      }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
